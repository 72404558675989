import PropTypes from 'prop-types';
import React from 'react';
import Button from '../../components-lib/Button/Button';
import styles from './Tile.module.scss';


const Tile = ({
  children,
  className = '',
  imageAltText,
  imageLink,
  href,
  onOpen,
  title,
}) => (
  <article className={`${styles.tile} ${className}`}>
    {imageLink && <img className={styles.image} src={imageLink} alt={imageAltText} />}
    {title && <h4 className={styles.title}>{title}</h4>}
    <section className={styles.content}>{children}</section>
    <Button className={styles.button} onClick={onOpen} gradientColor="blue">Čítať viac</Button>
    <a data-no-underline href={href}>
      <Button className={styles.button}>Mám záujem</Button>
    </a>
  </article>
);

Tile.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  imageAltText: (props, propName, componentName) => {
    if (props['imageLink'] && (!props[propName] || typeof props[propName] !== 'string')) {
      return new Error(
        `Invalid prop ${propName} supplied to ${componentName}. ` +
        `${propName} must be a non null string.`
      );
    }
  },
  imageLink: PropTypes.string,
  link: PropTypes.string,
  onClick: PropTypes.func,
  onOpen: PropTypes.func,
  title: PropTypes.node,
  href: PropTypes.string,
};

export default Tile;
