import React from 'react';
import Button from '../components-lib/Button/Button';
import ColoredText from '../components-lib/ColoredText/ColoredText';
import { FORM_ID } from '../components/ContactForm/constants';


const cardsContent = () => {
  const contactFormLink = (
    <>
      <p>Našli ste sa v tejto skupine a potrebujete pomoc?</p> 
      <p>
        <a data-no-hover data-no-underline href={`#${FORM_ID}`}>
          <Button>Potrebujem pomoc</Button>
        </a>
      </p>
    </>
  );

  return [{
    imageAltText: 'Ilustrácia ľudí protestujúcich proti diskriminácii',
    imageLink: '/svg/illustrations/discriminated-people.svg',
    title: 'Osoby ohrozené alebo postihnuté diskrimináciou',
    dialogTitle: 'Osoby ohrozené alebo postihnuté diskrimináciou',
    content: (
      <>
        <p>
          Antidiskriminačné právo zakazuje prípady, v ktorých sa s osobami v rovnakej situácii zaobchádza rozdielne alebo v ktorých sa s osobami v rôznych situáciách zaobchádza rovnako. Zákaz diskriminácie teda zjednodušene znamená, že sa <b>nemá rovnaké riešiť rozdielne a rozdielne rovnako</b>.
        </p>

        <p>
          <b>Diskriminácia</b> znamená, že sa osoba z určitého dôvodu znevýhodňuje oproti inej osobe v porovnateľnej situácii. Dôvod na znevýhodnenie musí spadať pod tzv. chránený dôvod. Medzi chránené dôvody patrí:
        </p>
        <ul>
          <li>
            <b>pohlavie alebo rod</b>, vrátane tehotenstva, materstva alebo zmeny rodu, resp. pohlavia, tzv. rodovej identity,
          </li>
          <li>
            <b>náboženské vyznanie alebo viera</b>, vrátane byť bez vyznania (ateista),
          </li>
          <li>
            <b>rasa, príslušnosť k národnosti alebo etnickej skupine, farby pleti, jazyk</b>, vrátane blízkeho vzťahu  k takejto osobe (napr. manželka moslima alebo černocha, dieťa rómskej matky),
          </li>
          <li>
            <b>zdravotné postihnutie</b>, vrátane blízkeho vzťahu k takejto osobe (napr. matka dieťaťa so zdravotným postihnutím),
          </li>
          <li><b>vek</b> (najmä vysoký alebo nízky),</li>
          <li><b>sexuálna orientácia</b>,</li> 
          <li><b>manželský stav a rodinný stav</b>,</li> 
          <li><b>politické alebo iné zmýšľanie</b>,</li> 
          <li><b>národný alebo sociálny pôvod</b>,</li> 
          <li><b>majetok</b>,</li>
          <li><b>iné postavenie</b>,</li>
          <li><b>oznámenie kriminality alebo inej protispoločenskej činnosti</b>.</li>
        </ul>
        <p>
          Dôležité je dokázať, že príčinou znevýhodnenia je práve chránený dôvod, čo sa dá položením jednoduchej otázky: <b><ColoredText color="red">“Došlo by k znevýhodneniu danej osoby, aj keby bola opačného pohlavia, inej rasy, iného veku, ak by neoznámila trestnú činnosť, ak by nebola whistlebloverom a pod?”</ColoredText></b> Ak odpoveď znie <b><ColoredText color="red">NIE</ColoredText></b>, potom k znevýhodneniu dochádza jednoznačne z daného dôvodu.
        </p>
        <p>
          Diskrimináciou nie je len <b>priama diskriminácia</b> (znevýhodňovanie na základe chráneného dôvodu), ale aj <b>nepriama diskriminácia</b> (zaobchádza sa rovnako s osobou, ktorá by si zaslúžila iné zaobchádzanie) a taktiež <b>obťažovanie a šikana</b>.
        </p>
        <p>
          Aj <b>obťažovanie, sexuálne obťažovanie a šikana, či už v škole, alebo na pracovisku (mobbing, bossing),</b> je diskrimináciou, a preto je zakázaná.
        </p>
        <p>Zásada rovnakého zaobchádzania sa uplatňuje najmä v prístupe k:</p>
        <ul>
          <li><b>zamestnaniu,</b></li> 
          <li><b>vzdelávaniu,</b></li> 
          <li><b>sociálnemu zabezpečeniu,</b></li> 
          <li><b>tovarom a službám, vrátane bývania a</b></li>
          <li><b>v prístupe k spravodlivosti.</b></li>
        </ul>

        <p>Diskriminácia sa však zakazuje aj</p>
        <ul>
          <li>
            <b>v prístupe rozvedených rodičov k deťom</b> a záležitostiam týkajúcim sa <b>určenia otcovstva</b>,
          </li>
          <li>
            v rámci <b>presadzovania práva</b>
          </li>
          <li>
            ako aj v <b>trestnoprávnych veciach.</b> 
          </li>
        </ul>

        {contactFormLink}
      </>
    ),
  }, {
    imageAltText: 'Ilustrácia ľudí zažívajúcich chudobu',
    imageLink: '/svg/illustrations/poverty.svg',
    title: <span>Osoby ohrozené<br />chudobou</span>,
    dialogTitle: 'Osoby ohrozené chudobou',
    content: (
      <>
        <p>
          <b>Chudoba</b> má rôzne prejavy a zahŕňa neexistenciu peňažného príjmu a iných zdrojov, ktoré by postačovali na zaistenie živobytia, bývania a ktoré často eskalujú do stavu hladu a podvýživy, zlého zdravotného stavu, k obmedzenému alebo žiadnemu prístupu k základným službám, zvýšenej úmrtnosti, bezdomovectva, nedostačujúcemu prístupu k bývaniu alebo k bývaniu nevyhovujúcemu, až po oslabenie rozhodovania v občianskom, kultúrnom a spoločenskom živote.
        </p>
        <p>
          <b>Za chudobné možno považovať</b> tie osoby, skupiny osôb a rodiny, ktorých zdroje materiálne, kultúrne a sociálne sú tak obmedzené, že ich vylučujú z minimálne akceptovateľného spôsobu života danej spoločnosti.
        </p>
        <p>
          Život v chudobe, vo väčšine prípadov, znamená byť permanentne v nedostatku. <b>Nedostatok</b> predstavuje stav, kedy je pociťovaný nedostatok vo forme fyzického (materiálny nedostatok) a psychického niečoho, čo je v danej spoločnosti všeobecne považované za hodnotu. Pocit nedostatku je úzko spájaný aj s pocitom hanby a neschopnosťou viesť dôstojný život.
        </p>
        <p>
          Za <b>hranicu chudoby</b> je možné považovať príjem jednotlivca nižší ako 359 Eur mesačne alebo 754 Eur mesačne pre 4-člennú rodinu (2 dospelí + 2 maloleté deti).
        </p>
        {contactFormLink}
      </>
    ),
  }, {
    imageAltText: 'Ilustrácia sociálne vylúčeného človeka',
    imageLink: '/svg/illustrations/social-outcast.svg',
    title: <span>Sociálne vylúčené<br />osoby</span>,
    dialogTitle: 'Sociálne vylúčené osoby',
    content: (
      <>
        <p>
          <b>Sociálne vylúčenie</b> je širší koncept než chudoba, vyjadruje riziko marginalizácie a vylúčenia pre jednotlivca a skupiny vo viacerých oblastiach života a vždy zahŕňa chudobu, kým chudoba nemusí vždy zahŕňať sociálne vylúčenie.
        </p>
        <p>
          Sociálne vylúčenie sa prejavuje najmä v oblastiach pracovného života, bývania, vzdelávania, zdravia a prístupu k službám.
        </p>
        <p><b>Najčastejšie prejavy sociálneho vylúčenia:</b></p>
        <ul>
          <li>
            Ekonomické prejavy (dlhodobá nezamestnanosť, nestálosť práce, domácnosť bez zamestnaného člena, príjmová chudoba).
          </li>
          <li>
            Sociálne prejavy (rozpad tradičnej rodiny a manželstva, nechcené tehotenstvo tínedžeriek, bezdomovectvo, kriminalita, delikvencia mládeže).
          </li>
          <li>
            Politické prejavy (nedostatok/upieranie politických práv, nízka participácia, nízky podiel na voľbách).
          </li>
          <li>
            Komunitné prejavy (zdevastované prostredie a obydlia, kolaps podporných sietí, nevyhovujúci sociálny servis a nedostupnosť sociálnych služieb).
          </li>
          <li>
            Individuálne prejavy (choroba alebo hendikep, nízke dosiahnuté vzdelanie a chýbajúca kvalifikácia, stratená sebadôvera a sebaúcta).
          </li>
          <li>
            Skupinové prejavy (koncentrácia uvedených charakteristík v konkrétnych sociálnych kategóriách, napr. etnickou minoritnou príslušnosťou).
          </li>
          <li>Priestorová/rezidenčná segregácia.</li>
        </ul>
        {contactFormLink}
      </>
    ),
  }, {
    imageAltText: 'Ilustrácia ľudí so zdravotným postihnutím',
    imageLink: '/svg/illustrations/people-with-disabilities.svg',
    title: <span>Znevýhodnené<br />osoby</span>,
    dialogTitle: 'Znevýhodnené osoby',
    content: (
      <>
        <p>
          <b>Zdravotné postihnutie</b> je trvalým zhoršením zdravotného stavu jednotlivca a ovplyvňuje jeho možnosť uplatnenia takmer vo všetkých sférach verejného i súkromného života.
        </p>
        <p>
          <b>Diskriminácia na základe zdravotného postihnutia</b> znamená <i>„každé robenie rozdielov, vylúčenie alebo obmedzenie na základe zdravotného postihnutia, ktorých cieľom alebo účinkom je narušiť alebo znemožniť uznávanie, užívanie alebo uplatňovanie všetkých ľudských práv a základných slobôd v politickej, hospodárskej, sociálnej, kultúrnej, občianskej alebo inej oblasti na rovnakom základe s ostatnými. Zahŕňa všetky formy diskriminácie, vrátane odmietnutia primeraných úprav. Primerané úpravy znamenajú nevyhnutnú a adekvátnu zmenu a prispôsobenie, ktoré nepredstavujú neúmerné alebo nadmerné zaťaženie, a robia sa, ak si to vyžaduje konkrétny prípad, s cieľom zabezpečiť osobám so zdravotným postihnutím užívanie alebo uplatňovanie všetkých ľudských práv a základných slobôd na rovnakom základe s ostatnými“</i>.
        </p>
        <p>
          <b>Formy zdravotného postihnutia</b> sú rôznorodé a na ich základe môžu takíto ľudia potrebovať rôzne opatrenia. Týka sa to najmä osôb s duševnými poruchami, osôb s chronickými chorobami, osôb s mentálnym postihnutím, osôb so sluchovým postihnutím, osôb s telesným postihnutím a osôb so zrakovým postihnutím.
        </p>
        <h6>Problém</h6>
        <ul>
          <li>
            Ľudia so zdravotným postihnutím čelia naďalej viacerým problémom nielen pri získaní prvého zamestnania, ale aj pri hľadaní nového pracovného miesta po strate predchádzajúceho. Mnohí z nich sa postupne presúvajú do skupiny dlhodobo nezamestnaných a ich potenciál zostáva v spoločnosti nevyužitý, nehovoriac o sociálnych dôsledkoch tejto situácie.
          </li>
          <li>
            Fyzické bariéry v dostupnosti verejných priestorov prehlbujú sociálne vylúčenie zdravotne postihnutých osôb z verejného, spoločenského a pracovného života.
          </li>
          <li>
            Predsudky o pracovnom výkone a využiteľných schopnostiach ľudí so zdravotným postihnutím (napr. podceňovanie alebo zneužívanie ich práce) a slabá motivácia súčasného podnikateľského prostredia v zamestnávaní týchto skupín obyvateľov vedie k ich diskriminácii pri vstupe na trh práce alebo priamo na trhu práce.
          </li>
        </ul>
        <h6>Právo na primerané úpravy</h6>
        <p>
          Ľudia so zdravotným postihnutím si spravidla vyžadujú <b>osobitný prístup a vytvorenie mimoriadnych podmienok</b> (napr. bezbariérové architektonické prostredie, prístupné informácie a pod.), bez ktorých nie je pre nich možné plne sa začleniť do spoločnosti a do pracovného procesu.
        </p>
        <p>
          Zdravotne postihnuté osoby sú v mnohých prípadoch odkázané na prijatie osobitných opatrení, bez ktorých by nemali vôbec šancu a prístup k realizácii svojich práv.
        </p>
        {contactFormLink}
      </>
    ),
  }, {
    imageAltText: 'Ilustrácia ľudí, ktorí hľadajú azyl',
    imageLink: '/svg/illustrations/asylum-seakers.svg',
    title: 'Azylanti',
    dialogTitle: 'Azylanti',
    content: (
      <>
        <p>
          <b>Migrant</b> je širší pojem a ide o osobu, ktorá sa premiestňuje z vlastnej vôle, dobrovoľne. Dôvody môžu byť viaceré: štúdium, práca, prípadne rodinné dôvody.
        </p>
        <p>
          <b>Utečenec</b> nemá na výber, je donútený opustiť svoju krajinu kvôli prenasledovaniu, či vojne a väčšinou mu ide o  život, v tomto prípade hovoríme  nedobrovoľnej alebo tiež nútenej migrácii.
        </p>
        <p>
          V slovenskom právnom poriadku bol pojem utečenec nahradený pojmom <b>azylant</b>, v  európskom priestore je zaužívaný pojem <b>osoba s udelenou medzinárodnou ochranou</b>.
        </p>
        <p>
          <b>Žiadateľ o azyl alebo azylant</b> je teda osoba, ktorá má opodstatnené obavy z prenasledovania z rasových, náboženských, národnostných a politických dôvodov alebo pre príslušnosť k určitej sociálnej skupine a z dôvodu týchto obáv sa nemôže alebo nechce vrátiť do krajiny pôvodu, ani využiť ochranu tejto krajiny. Azylantom je tiež osoba bez štátnej príslušnosti, ktorá sa z vyššie spomenutých dôvodov nemôže alebo nechce vrátiť do krajiny svojho obvyklého bydliska.
        </p>
        {contactFormLink}
      </>
    ),
  }];
};

export default cardsContent;
