import React, { useRef, useState } from 'react';
import Button from '../../components-lib/Button/Button';
import ColoredText from '../../components-lib/ColoredText/ColoredText';
import Dialog from '../../components-lib/Dialog/Dialog';
import Card from '../../components/Card/Card';
import Carousel from '../../components/Carousel/Carousel';
import { FORM_ID } from '../../components/ContactForm/constants';
import Section from '../../components/Section/Section';
import Tile from '../../components/Tile/Tile';
import useHashNavigationEffect from '../../hooks/useHashNavigationEffect';
import cardsContent from '../../utils/cardsContent';
import tilesContent from '../../utils/tilesContent';
import styles from './index.module.scss';


const CARDS_SECTION_ID = 'info-cards';

const Home = () => {
  const [dialogProps, setDialogProps] = useState(null);

  const cards = cardsContent();

  useHashNavigationEffect(() => {
    setDialogProps(null);
  });

  return (
    <>
      <Section
        className={styles.mainSection}
        wrapperContentClassName={styles.mainSectionWrapperContent}
        wrapperClassName={styles.mainSectionWrapper}
      >
        <section className={styles.mainSectionContent}>
          <h1>
            <ColoredText>Právna pomoc</ColoredText> pre osoby, ktoré sa cítia diskriminované
          </h1>
          <p>
            Prostredníctvom tejto stránky môžete získať informácie, ako získať právnu pomoc, ak sa cítite diskriminovaný, znevýhodnený alebo sociálne vylúčený.
          </p>
          <a data-no-hover data-no-underline href={`#${CARDS_SECTION_ID}`}>
            <Button className={styles.mainSectionContentButton}>
              Viac informácií
            </Button>
          </a>
        </section>
        <figure className={styles.mainSectionFigure}>
          <img
            src="/svg/illustrations/people-communing.svg"
            alt="Ilustrácia ľudí v sociálnej aktivite"
          />
        </figure>
      </Section>
      <Section
        className={styles.cardsSection}
        iconAltText="Ikonka pre sekciu «Pre koho je pomoc určená»"
        iconLink="/svg/icons/help.svg"
        title={<span>Pre koho je <ColoredText>pomoc</ColoredText> určená?</span>}
        id={CARDS_SECTION_ID}
      >
        <section id="pre-koho-je-pomoc-urcena" className={styles.cards}>
          {cards.map((props, index) => (
            <Card
              key={`cards-section-card-${index}`}
              className={styles.card}
              onClick={() => setDialogProps({
                content: props.content,
                title: props.dialogTitle,
              })}
              {...props}
            />
          ))}
        </section>
        <a data-no-hover data-no-underline href={`#${FORM_ID}`}>
          <Button className={styles.cardsButton}>
            Mám záujem o konzultáciu
          </Button>
        </a>
      </Section>
      <Section
        className={styles.tilesSection}
        iconAltText="Ikonka pre sekciu «Ako Vám pomôžeme?»"
        iconLink="/svg/icons/sections.svg"
        title={<span>Ako <ColoredText>Vám</ColoredText> pomôžeme?</span>}
        subtitle="Právne poradenstvo"
      >
        <Carousel name="info-tiles">
          {tilesContent.map((props, index) => (
            <Tile
              key={`tiles-section-tile-${index}`}
              className={styles.tile}
              href={`#${FORM_ID}`}
              onOpen={() => setDialogProps({
                content: props.content,
                title: props.dialogTitle,
              })}
              {...props}
            >
              {props.content}
            </Tile>
          ))}
        </Carousel>
      </Section>
      <Section
        className={styles.aboutSection}
        iconAltText="Ikonka pre sekciu «O nás»"
        iconLink="/svg/icons/info.svg"
        title="O nás"
      >
        <p>
          Keď sme opustili dobre naštartované kariéry v renomovaných právnických firmách a významných štátnych inštitúciách, rozhodli sme sa, že budeme robiť právo inak. Férovo, odborne, s jasne vymedzenými hodnotami. Verili sme v intuíciu a mali sme sen vybudovať slovenskú rodinnú advokátsku kanceláriu, v ktorej sa klienti budú cítiť ako doma.
        </p>
        <p>
          Dnes vieme, že sa nám to podarilo. Zúročili sme naše zahraničné štúdium, pôsobenie v medzinárodných kanceláriách a znalosť domáceho trhu. Našu prácu chápeme ako poslanie a vykonávame ju čestne, poctivo a diskrétne. Nebojíme sa postaviť za práva našich klientov, podržať ich v ťažkých situáciách a zastupovať ich proti silným protivníkom. A zároveň byť ľudskí a otvorení. Často stojíme za životnými rozhodnutiami našich klientov, a preto nám záleží na dlhodobých osobných vzťahoch a vzájomnej dôvere. 
        </p>
        <p>
          Po rokoch poskytovania profesionálnych právnych služieb na najvyššej úrovni cítime potrebu využiť nadobudnuté vedomosti a skúsenosti v prospech tých najslabších a najzraniteľnejších, ktorí nemajú rovnaké a spravodlivé možnosti na uplatnenie svojich práv. Rovnosť všetkých ľudí pred zákonom a zákaz diskriminácie je jednou zo základných zásad právneho štátu, no napriek tomu je rovnosť príležitostí všetkých obyvateľov Slovenska od zamýšľaného ideálu ďaleko vzdialená. Naším cieľom je prispieť k efektívnemu boju proti všetkým formám diskriminácie a napomôcť jej predchádzaniu. Chceme podať pomocnú ruku každému, kto je postihnutý alebo ohrozený diskrimináciou, chudobou alebo sociálnym vylúčením. Uplatňovanie, obhajovanie a vymáhanie dodržiavania práva je pre efektívny a účinný boj s diskrimináciou nesmierne dôležité, a preto veríme, že naša iniciatíva môže súčasnému stavu v oblasti diskriminácie výrazne napomôcť a priniesť trvalú zmenu v našej spoločnosti.
        </p>
      </Section>
      <Dialog
        isOpen={dialogProps !== null}
        onClose={() => setDialogProps(null)}
        {...dialogProps}
      />
    </>
  );
};

export default Home;
