import React from 'react';
import Button from '../components-lib/Button/Button';
import { Phone } from '../components-lib/Icons';


export default [{
  imageAltText: 'Ikonka displeja',
  imageLink: '/svg/icons/pc.svg',
  title: <span>Online<br />právna poradňa</span>,
  dialogTitle: 'Online právna poradňa',
  content: (
    <p>
      Poskytnúť pomoc vieme aj prostredníctvom e-mailu. Stačí nás kontaktovať, opísať celý problém a my sa Vám ozveme naspäť s odpoveďou.
    </p>
  ),
}, {
  imageAltText: 'Ikonka telefonickej podpory',
  imageLink: '/svg/icons/phone-support.svg',
  title: <span>Telefonická<br />konzultácia</span>,
  dialogTitle: 'Telefonická konzultácia',
  content: (
    <>
      <p>
        Vieme Vám poskytnúť aj telefonickú konzultáciu ako najrýchlejší spôsob komunikácie. Počas hovoru si dohodneme postup, aby sme čo najskôr vyriešili Váš problém.
      </p>
      <p>Zavolajte nám a my Vám pomôžeme vyriešiť Váš problém.</p>
      <Button as="a" href="tel:421-911-558-332" gradientColor="blue">
        <Phone color="white" />
        &nbsp;
        +421 911 558 332
      </Button>
    </>
  ),
}, {
  imageAltText: 'Ikonka rozhovoru dvoch ľudí',
  imageLink: '/svg/icons/conversation.svg',
  title: <span>Osobná<br />konzultácia</span>,
  dialogTitle: 'Osobná konzultácia',
  content: (
    <p>
      Kontaktujte nás na našom telefónnom čísle alebo e-mailom, aby sme sa dohodli na spoločnom stretnutí v sídle advokátskej kancelárie alebo kdekoľvek to bude pre Vás vyhovujúce. Na tomto stretnutí preberieme všetky detaily Vášho právneho problému a navrhneme potrebné kroky na jeho vyriešenie.
    </p>
  ),
}, {
  imageAltText: 'Ikonka ozubeného kolieska',
  imageLink: '/svg/icons/gear.svg',
  title: <span>Komplexné poskytovanie<br />právnych služieb</span>,
  dialogTitle: 'Komplexné poskytovanie právnych služieb',
  content: (
    <>
      <p>
        V advokátskej kancelárii poskytujeme komplexné právne služby, takže dokážeme riešiť Váš problém od jeho začiatku až po vyriešenie. Poskytujeme komplexnú právnu ochranu od prvých náznakov diskriminácie až po prípadné získanie finančného zadosťučinenia.
      </p>
      <p>
        Vieme Vás zastúpiť už pri rokovaní s protistranou, osobou alebo organizáciou, ktorá Vás diskriminuje, obťažuje alebo šikanuje, pripravíme pre Vás všetky podania od sťažností až po podanie na súd, Ústavný súd alebo aj Európsky súd pre ľudské práva, budeme vo Vašom mene žiadať o upustenie od diskriminačného konania, o ospravedlnenie alebo aj o primerané finančné zadosťučinenie.
      </p>
      <a href="/moznosti-ochrany">Ako sa brániť proti diskriminácii?</a>
      <p>Dohodnite si s nami konzultáciu a pomôžeme Vám vyriešiť Váš problém.</p>
    </>
  ),
}];
