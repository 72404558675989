import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { blockScrollClassName } from '../../styles/exports';
import { CloseX } from '../Icons';
import styles from './Dialog.module.scss';


const Dialog = ({ children, className = '', content, isOpen = false, onClose, title }) => {
  useEffect(() => {
    if (isOpen) document.body.classList.add(blockScrollClassName);
    else document.body.classList.remove(blockScrollClassName);
  }, [isOpen]);;

  useEffect(() => {
    const handleClick = (event) => {
      if (event.target.closest(`.${styles.dialog}`)) return;
      if (isOpen) onClose();
    };

    const handleKeyUp = (event) => {
      if (isOpen && event.key === 'Escape') onClose();
    };

    document.addEventListener('click', handleClick);
    document.addEventListener('keyup', handleKeyUp);
  
    return () => {
      document.removeEventListener('click', handleClick);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [isOpen, onClose]);

  return (
    <>
      <dialog open={isOpen} className={`${styles.dialog} ${className}`}>
        <header className={styles.header}>
          {title && <h3 className={styles.title}>{title}</h3>}
          <button className={styles.closeButton} onClick={onClose} >
            <CloseX size={24} />
          </button>
        </header>
        <article className={styles.content}>
          {children || content}
        </article>
      </dialog>
      <div className={styles.dimmer} />
    </>
  );
};

Dialog.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  content: PropTypes.node,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.node,
};

export default Dialog;
