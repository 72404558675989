import { useEffect } from 'react';


const useHashNavigationEffect = (callback) => {
  useEffect(() => {
    const handleHashChange = () => {
      const target = document.querySelector(window.location.hash);
      if (target) {
        setTimeout(() => target.scrollIntoView(), 0);
        callback?.();
      }
    };

    window.addEventListener('hashchange', handleHashChange);
    return () => window.removeEventListener('hashchange', handleHashChange);
  }, [callback]);
};

export default useHashNavigationEffect;
