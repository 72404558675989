import PropTypes from 'prop-types';
import React from 'react';
import { ChevronLeft, ChevronRight } from '../../components-lib/Icons';
import styles from './Card.module.scss';


const Card = ({
  children = 'Patríte do tejto skupiny ?',
  className = '',
  imageAltText,
  imageLink,
  onClick,
  title,
}) => (
  <article className={`${styles.card} ${className}`}>
    {imageLink && <img className={styles.image} src={imageLink} alt={imageAltText} />}
    {title && <h3 className={styles.title}>{title}</h3>}
    <section className={styles.content}>{children}</section>
    <button className={styles.button} onClick={onClick}>
      <ChevronRight color="orange" /> Dozviete sa tu <ChevronLeft color="orange" />
    </button>
  </article>
);

Card.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  imageAltText: (props, propName, componentName) => {
    if (props['imageLink'] && (!props[propName] || typeof props[propName] !== 'string')) {
      return new Error(
        `Invalid prop ${propName} supplied to ${componentName}. ` +
        `${propName} must be a non null string.`
      );
    }
  },
  imageLink: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.node,
};

export default Card;
