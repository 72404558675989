import parseInt from 'parse-int';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import styles from './Carousel.module.scss';


const Carousel = ({ children, name }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const itemNamePrefix = `${name}-carousel-item`;

  const childrenArray = React.Children.map(children, child => React.cloneElement(child));

  const scrollIntoView = useCallback((index) => {
    document.getElementById(`${itemNamePrefix}-${index}`).scrollIntoView(false);
    setCurrentIndex(index);
  }, [itemNamePrefix, setCurrentIndex]);

  useEffect(() => {
    if (!('IntersectionObserver' in window)) return;

    const getItemId = (item) => parseInt(item.id?.charAt(item.id.length - 1));

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) setCurrentIndex(getItemId(entry.target));
      });
    }, {
      root: document.querySelector(`.${styles.items}`),
      threshold: 0.6, 
    });

    const items = document.querySelectorAll(`.${styles.item}`);
    items.forEach(item => observer.observe(item));

    return () => items.forEach(item => observer.unobserve(item));
  }, []);

  return (
    <section className={styles.carousel}>
      <div className={styles.items}>
        {childrenArray.map((child, index) => (
          <div
            key={`${itemNamePrefix}-${index}`}
            id={`${itemNamePrefix}-${index}`}
            className={styles.item}
          >
            {child}
          </div>
        ))}
      </div>
      <div className={styles.indicators}>
        {childrenArray.map((_, index) => (
          <button
            key={`${name}-carousel-indicator-${index}`}
            className={`${styles.indicator} ${index === currentIndex ? styles.indicatorActive : ''}`}
            aria-label={`${name} carousel item number ${index}`}
            onClick={() => scrollIntoView(index)}
          />
        ))}
      </div>
    </section>
  );
};

Carousel.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string.isRequired,
};

export default Carousel;
